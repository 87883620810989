import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { formatDate } from '../components/common';
import EmptyState from '../components/empty-state';
import styles from '../styles/pages.module.css';

export const query = graphql`
	{
		cms {
			allArticles(sortBy: [posted_DESC]) {
				title
				posted
				id
				slug
				tags {
					name
				}
				authors {
					name
					image {
						publicUrl
						gatsbyFile {
							childImageSharp {
								fluid(maxWidth: 700, maxHeight: 700) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
				image {
					publicUrl
					gatsbyFile {
						childImageSharp {
							fluid(maxWidth: 700, maxHeight: 700) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`;

export default ({ data }) => (
	<Layout id={styles.article}>
		<SEO
			title='Articles'
			keywords={[
				'article',
				...data.cms.allArticles.flatMap(({ tags }) => tags.map(({ name }) => name)),
				...data.cms.allArticles.flatMap(({ authors }) => authors.map(({ name }) => name))
			]}
			description='IEEE SSCS AlexSC Articles'
		/>
		<h1>Articles</h1>

		{data.cms.allArticles.length === 0 ? (
			<EmptyState />
		) : (
			<ul className='list-none'>
				{data.cms.allArticles.map((node, ind) => (
					<li key={ind}>
						<Link to={`/articles/${node.slug}`}>
							<p>{formatDate(node.posted)}</p>
							<hr />
							<div className='card paper'>
								{node.image ? <Img fluid={node.image.gatsbyFile.childImageSharp.fluid} /> : null}
								<section>
									<h2>{node.title}</h2>
								</section>
							</div>
						</Link>
					</li>
				))}
			</ul>
		)}
	</Layout>
);
